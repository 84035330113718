import React from 'react'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InPageImage from '../components/in-page-image'
import SubHero from '../components/sub-hero'
import CtaBlue from '../components/cta-blue'

import HeroBackgroundImage from './../images/store-hero.png'

import { Payment, Lock, InsertChart, Assignment, LocalOffer, CloudDownload, LocalCarWash } from '@material-ui/icons'
import DiscountBanner from '../components/discount-banner'
import DiscountTerms from '../components/discount-terms'

class IndexPage extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title='Online Store'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'product_page'}
          flowType={'none'} />
        <DiscountBanner />
        <SubHero
          pageClass={'store-hero'}
          title={'Create a store, start selling today'}
          subTitle={'Build a free online store in minutes. Seriously.'}
          buttonTitle={'Get started free'}
          backgroundImage={HeroBackgroundImage}
          buttonLink={process.env.GATSBY_SIGNUP_STORE_URL}
          currentPage={'online-store'}
          dataElementLocation={DataElementLocations.NAV}
          buttonVisibility={'show-button'} />
        <section>
          <div className='container section-padding text-section large-gutter'>
            <div className='row'>
              <div className='col-md-6'>
                <h3>Sell anywhere, at any time, from any device</h3>
                <p className='black-60'>All of our stores are fully mobile responsive so your customers can checkout easily from anywhere, on any device, at any time.</p>
                <p className='black-60'>Plus, you get the flexibility of selling any type of product – physical, digital, or a service.</p>
              </div>
              <div className='col-md-6 image' style={{ marginTop: 0 }}><InPageImage fileName='store-1.png' alt='Person using store on tablet' /></div>
            </div>
            <div className='row'>
              <div className='col-md-6 image'><InPageImage fileName='store-2.png' alt='Person readying a package to ship' /></div>
              <div className='col-md-6 swap'>
                <h3>Sell, ship and manage your products</h3>
                <p className='black-60'>We’ll help to configure your settings so you don’t have to stress about payment providers, taxes and shipping methods.</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <h3>Make your products stand out</h3>
                <p className='black-60'>Our store brings your products to life to connect customers with each offering in your store.</p>
                <p className='black-60'>We’ll guide you to quickly set up products to start selling with no technical expertise required.</p>
              </div>
              <div className='col-md-6 image'><InPageImage fileName='store-3.jpg' alt='Laptop with store' /></div>
            </div>
            <div className='row'>
              <div className='col-md-6 image' style={{ marginBottom: 0 }}><InPageImage fileName='store-4.jpg' alt='Desktop with store' /></div>
              <div className='col-md-6 swap'>
                <h3>Create a beautiful website that matches your store</h3>
                <p className='black-60'>Stores are fully integrated with our intelligent builder that does all the hard work to seamlessly create a website that matches your storefront.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-lightgray'>
          <div className='container section-padding'>
            <div className='row tagline'>
              <div className='col-12'>
                <div className='line' />
                <h2>All of our stores include these benefits</h2>
                <div className='line' />
              </div>
            </div>
            <div className='row text-center store-benefits'>
              <div className='col-md-6 col-lg-3'>
                <Payment className='icon' />
                <h4>Flexible payment and shipping</h4>
                <p className='black-60'>Accept all major credit cards, including PayPal. Set up several shipping options along with email notifications.</p>
              </div>
              <div className='col-md-6 col-lg-3'>
                <Lock className='icon' />
                <h4>The confidence of security</h4>
                <p className='black-60'>Your customer's data and payment information will always be fully secure and protected.</p>
              </div>
              <div className='col-md-6 col-lg-3'>
                <InsertChart className='icon' />
                <h4>Analytics</h4>
                <p className='black-60'>Track your visitors, orders, and revenue information. Optimize your sales for sustained growth.</p>
              </div>
              <div className='col-md-6 col-lg-3'>
                <Assignment className='icon' />
                <h4>Inventory management</h4>
                <p className='black-60'>Automatically adjust inventory levels when products sell, and get alerts when your inventory becomes low.</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container section-padding product-cards'>
            <div className='row'>
              <div className='col-md-8 text-center mx-auto' style={{ marginBottom: 16 }}>
                <h2>Sell any type of product that you business needs</h2>
                <p className='black-60 body-large no-margin'>Leave your credit card behind, let's start building your free store</p>
              </div>
            </div>
            <div className='row text-center'>
              <div className='col-md-4'>
                <div className='card'>
                  <LocalOffer className='icon' />
                  <h4>Physical product</h4>
                  <p className='black-60 no-margin'>Sell physical items like clothing, pet supplies or a watch</p>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='card'>
                  <CloudDownload className='icon' />
                  <h4>Digital download</h4>
                  <p className='black-60 no-margin'>Sell digital products like stock photography, ebooks, or your band's new album</p>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='card'>
                  <LocalCarWash className='icon' />
                  <h4>Services</h4>
                  <p className='black-60 no-margin'>Sell services such as tutoring, professional services, or car detailing</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-10 body-large mx-auto'>
                <CtaBlue
                  eventName='start_onboarding_online_store'
                  title={'Try it first.  No commitment necessary.'}
                  buttonTitle={'Try it for free'}
                  buttonLink={process.env.GATSBY_SIGNUP_STORE_URL}
                  currentPage={'features'}
                  dataElementLocation={DataElementLocations.BODY} />
              </div>
            </div>
          </div>
        </section>
        <DiscountTerms />
      </Layout>
    )
  }
}

export default IndexPage
